<template>
  <div @click="toOrderDetail">
    <Card class="card">
      <div
        class="card-title"
        slot="title"
      >
        <h3 style="display: flex;align-items: center;">
          <span style="margin-right: 8px;">No.{{cardInfo.orderNum}}</span>
          <Tag
            v-clipboard:copy="cardInfo.orderNum"
            v-clipboard:success="onCopy"
            plain
            round
            @click.stop
          >Copy</Tag>
        </h3>
        <Tag type="primary">{{cardInfo._status}}</Tag>
      </div>
      <CardCell
        v-for="($item, ind)  in cardInfo.info"
        :key="ind"
        :label="$item.name"
        :value="$item.val"
        label-width="2rem"
      />
      <template slot="footer">
        <span style="font-size:12px"> Sales Advisor: {{cardInfo.makeEmpName}}</span>
      </template>
    </Card>
  </div>
</template>
<script>
import { Tag, Toast } from 'vant'
import Card from '_c/card'
import CardCell from '_c/card/cardCell'

export default {
  name: 'CardItem',
  components: {
    Tag,
    Card,
    CardCell
  },
  props: {
    cardInfo: {
      type: Object,
      require: true
    }
  },
  data () {
    return {
    }
  },
  methods: {
    onCopy () {
      Toast('复制成功')
    },
    toOrderDetail () {
      this.$router.push({
        path: 'orderDetail',
        query: {
          orderNum: this.cardInfo.orderNum
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.card {
  .card-title {
    display: flex;
    justify-content: space-between;
  }
}
</style>